import { MdSupportAgent } from "react-icons/md";

function App() {
  return <main className="overflow-x-hidden p-3 pt-10">
    <article className="max-w-3xl text-center flex flex-col gap-4 mx-auto">
    <div className="w-full mx-auto flex flex-row justify-center gap-10">
          <img className="w-28" src="https://images.credly.com/images/be8fcaeb-c769-4858-b567-ffaaa73ce8cf/twitter_thumb_201604_image.png" alt="Microsoft Certified Azure Fundamentals" />
          <img className="w-28" src="https://images.credly.com/images/70eb1e3f-d4de-4377-a062-b20fb29594ea/twitter_thumb_201604_azure-data-fundamentals-600x600.png" alt="Microsoft Certified Azure Data Fundamentals" />
        </div>
      <h1 style={{ fontFamily: 'vollkorn', fontWeight: 400 }} className="md:text-5xl text-4xl">
        <MdSupportAgent className="w-20 h-20 mx-auto" />
        <u>IT-Support</u> <b>zuverlässig und günstig</b> für <i>dein Unternehmen</i>.
      </h1>
      <img className="w-32 absolute top-3/4 rotate-45 opacity-75 -z-10" src="https://th.bing.com/th/id/R.347e30e321b9efd4d7d626d513baa0b5?rik=u5GFKu4Low%2b6qA&riu=http%3a%2f%2fwww.pngmart.com%2ffiles%2f9%2fCurved-Arrow-PNG-Background-Image.png&ehk=dTEf%2bmiB0K84wDwtiE%2fNThkFm13uw7MAOUYMlf0WqN4%3d&risl=&pid=ImgRaw&r=0" />
        <p className="max-w-xl mx-auto">
          Mit meiner IT-Support Hotline helfe ich meinen Kunden mit ihren technischen Problemen, damit Sie sich weiter auf Ihr Tagesgeschäft konzentrieren können.
        </p>
        <p className="mx-auto underline tracking-wider mt-5 italic">
        Ich erkläre dir gerne persönlich wie wir zusammenarbeiten können
        </p>
      <div className="flex flex-col gap-4 justify-center">
        <a href="https://wa.me/491749300842" rel="noreferrer" className="hover:ring-4 transition-all px-8 py-2 w-56 mx-auto rounded-lg font-bolder text-white bg-black">WhatsApp öffnen</a>
        <a href="mailto:omar.bittar@bitrate-it.de" rel="noreferrer" className="hover:ring-4 transition-all px-8 py-2 w-56 mx-auto rounded-lg font-bolder">E-Mail senden</a>
        <a href="https://www.linkedin.com/in/omar-bittar/" rel="noreferrer" className="transition-all px-8 py-2 mx-auto rounded-lg font-bold text-blue-900">Besuche mein LinkedIn Profil</a>
        <a className="linkedinprofile border cursor-pointer" href="https://www.linkedin.com/in/omar-bittar/" target="_blank" rel="noreferrer">
        </a>
      </div>
    </article>
  </main>
}

export default App;
